import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserProfile, getCurrentlySignedInUser, getDocket, addJob, getDB } from '../api/FirebaseAPI';
import '../css/Details.css';
import CustomButton from '../components/CustomButton';
import Overlay from '../components/Overlay';

const Details = ({ match }) => {

    const [docket, setDocket] = useState();
    const [selectedItems, setSelectedItems] = useState([]);

    const [user, setUser] = useState();
    // const [profile, setProfile] = useState();
    // const [loading, setLoading] = useState(true);
    const [overlay, setOverlay] = useState(false);
    const [items, setItems] = useState([]);


    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());

        if (user) {
            const ref = getDB().collection('dockets: ' + user.uid).doc(match.params.id).collection('jobs: ' + match.params.id).orderBy('timeStamp');

            return ref.onSnapshot(querySnapshot => {
                const list = [];

                querySnapshot.forEach(doc => {
                    let data = doc.data();

                    list.push({
                        ...data, id: doc.id
                    });
                });

                setItems(list);


            });
        }



    }, [user]);

    const setupUser = (user) => {
        setUser(user);
        getUserProfile().doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                // setProfile(doc.data());
            }
        });

        getDocket(user.uid, match.params.id, doc => {
            if (doc.exists) {
                setDocket(doc.data());
            }

        }, error => {
            console.log(error);
        });

        // setLoading(false);

    }

    const redirectToLogin = () => {
        history.push("/");
    }

    const openOverlay = () => {
        setOverlay(true);
    }

    const closeOverlay = () => {
        setOverlay(false);
    }

    const newRow = data => {
        addJob(user.uid, match.params.id, data, () => {
            //job added
        }, (error) => {
            alert(error);
        })
    }

    const selectRow = (evt, id) => {
        //check if item is already selected
        if (selectedItems.includes(id)) {
            //remove from array
            let index = selectedItems.indexOf(id);
            selectedItems.splice(index, 1);
            let temparray = Array.from(selectedItems);
            setSelectedItems(temparray);
        } else {
            //add to array
            selectedItems.push(id);
            let temparray = Array.from(selectedItems);
            setSelectedItems(temparray);
        }

    }

    const isSelected = (id) => {
        if (selectedItems.includes(id)) return true;

        return false;
    }

    const deleteSelected = () => {
        //prompt deletion

        var batch = getDB().batch();

        //make deletions
        selectedItems.forEach(id => {
            var ref = getDB().collection('dockets: ' + user.uid).doc(match.params.id).collection('jobs: ' + match.params.id).doc(id);
            batch.delete(ref);
        });

        //complete
        batch.commit().then(() => {
            //refresh selected list
            setSelectedItems([]);
        }).catch(error => {
            console.log("error deleting dockets: ", error);
        })
    }

    return (
        <div className="detail-container">

            <Overlay closeOverlay={closeOverlay}
                onSubmit={newRow}
                isVisible={overlay}
            />


            <h1>Details</h1>

            <div className="detail-date">
                <h3>Date:</h3>
                <p>{docket && docket.createdAt}</p>
            </div>

            <div className="detail-header">
                <table>
                    <tbody>
                        <tr>
                            <td className="detail-table-heading">
                                <h3>Name: </h3>
                            </td>
                            <td>
                                {docket && docket.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="detail-table-heading">
                                <h3>Make: </h3>
                            </td>
                            <td>
                                {docket && docket.make}
                            </td>
                        </tr>
                        <tr>
                            <td className="detail-table-heading">
                                <h3>Model: </h3>
                            </td>
                            <td>
                                {docket && docket.model}
                            </td>
                        </tr>
                        <tr>
                            <td className="detail-table-heading">
                                <h3>VIN: </h3>
                            </td>
                            <td>
                                {docket && docket.vin}
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>

            <div className="detail-exports">
                <CustomButton title="PDF" type="btn-secondary" />
                <CustomButton title="Excel" type="btn-secondary" />
            </div>

            <div className="detail-item-container">
                <table className="detail-item-table">
                    <thead>
                        <tr>
                            <th>
                                QTY
                        </th>
                            <th>
                                Description
                        </th>
                            <th>
                                Amount
                        </th>
                            <th>
                                Rate
                        </th>
                        </tr>
                    </thead>

                    <tbody>
                        {items.map(item => {
                            return (
                                <tr className={isSelected(item.id) ? "selected" : ""} key={item.id} onClick={evt => { selectRow(evt, item.id) }}>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    <td>
                                        {item.desc}
                                    </td>
                                    <td>
                                        {item.amount}
                                    </td>
                                    <td>
                                        {item.rate}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>


                </table>
            </div>

            <div className="detail-footer">
                <CustomButton title="Delete Selected" onClick={deleteSelected} />
                <CustomButton title="Add New Row" onClick={openOverlay} />
                <CustomButton title="Submit" />
            </div>
        </div>
    );
}

export default Details;