import React, { useState, useEffect } from 'react';
import '../css/NewDocket.css';
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { getUserProfile, getCurrentlySignedInUser, addDocket, incrementDocket } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';

const NewDocket = () => {
    const [name, setName] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [vin, setVin] = useState('');
    const [error, setError] = useState("");

    // const [profile, setProfile] = useState(false);
    const [user, setUser] = useState();
    // const [loading, setLoading] = useState(true);


    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());

    }, []);

    const setupUser = (user) => {
        setUser(user);
        getUserProfile().doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                // setProfile(doc.data());
                // setLoading(false);
            }
        });
    }

    const redirectToLogin = () => {
        history.push("/");
    }


    const updateName = (e) => {
        setName(e.target.value);
        setError("");
    }

    const updateMake = (e) => {
        setMake(e.target.value);
        setError("");
    }

    const updateModel = (e) => {
        setModel(e.target.value);
        setError("");
    }

    const updateVin = (e) => {
        setVin(e.target.value);
        setError("");
    }

    const submit = () => {
        if (!validate()) {
            setError("invalid inputs!");
            return;
        }

        let data = {
            name: name,
            make: make,
            model: model,
            vin: vin,
            createdAt: new Date().toDateString(),
        };

        addDocket(user.uid, data, () => {
            //once added update profile here
            incrementDocket(user.uid);
            history.push("/dockets");
        }, err => {
            setError(error);
        })
    }

    const validate = () => {
        if (name.trim() === '') return false;
        if (make.trim() === '') return false;
        if (model.trim() === '') return false;
        if (vin.trim() === '') return false;

        return true;
    }

    return (
        <div className="newdocket-container" >
            <h1>New Job Card</h1>
            <div className="newdocket-card">
                <CustomInput label="Name" placeholder="Name" type="text" onChange={updateName} />
                <CustomInput label="Make" placeholder="Make" type="text" onChange={updateMake} />
                <CustomInput label="Model" placeholder="Model" type="text" onChange={updateModel} />
                <CustomInput label="VIN" placeholder="VIN" type="text" onChange={updateVin} />
                <CustomButton title="Create" type="btn-primary" onClick={submit} />
                <div className="newdocket-error">
                    {error}
                </div>
            </div>
        </div>
    );
}

export default NewDocket;