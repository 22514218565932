import React, { useState } from 'react';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';
import { createUser, createUserProfile } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';

import '../css/SignUp.css';

const SignUp = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")

    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    let history = useHistory();

    const updateName = (e) => {
        setName(e.target.value);
        setError(false);
    }
    const updateEmail = (e) => {
        setEmail(e.target.value);
        setError(false);
    }

    const updatePassword = (e) => {
        setPassword(e.target.value);
        setError(false);
    }

    const updateConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        setError(false);
    }

    const handleSubmit = () => {
        if (validateInput()) {

            createUser(email, password, cred => {
                const data = { name: name, email: email };
                createUserProfile(data, cred.user.uid);
                history.push('/dashboard');
            }, (e) => {
                setErrMsg(e);
                setError(true);
            })

        }
    }

    const validateInput = () => {
        if (password !== confirmPassword) {
            setError(true);
            setErrMsg("Passwords must match!");
            return false;
        }

        if (password.trim().length < 6) {
            setError(true);
            setErrMsg("Password must be 6 chars or more!");
            return false;
        }

        if (name.trim().length < 3) {
            setError(true);
            setErrMsg("Name must be 3 chars or more!");
            return false;
        }

        return true;

    }


    return (
        <div className="signup-container">
            <div className="signup-card">
                <div className="signup-card-header">
                    <h3>Sign Up</h3>
                </div>
                <div className="signup-inputs">
                    <CustomInput label="Display Name" placeholder="Name" type="text" onChange={updateName} />
                    <CustomInput label="Email" placeholder="Email" type="email" onChange={updateEmail} />
                    <CustomInput label="Password" placeholder="Password" type="password" onChange={updatePassword} />
                    <CustomInput label="Confirm Password" placeholder="Confirm Password" type="password" onChange={updateConfirmPassword} />
                    <CustomButton title="Submit" type="btn-primary" onClick={handleSubmit} />
                </div>
                <div className="login-error">
                    {error ? errMsg : ""}
                </div>
            </div>
        </div>
    );
}

export default SignUp;