import React, { useState } from 'react';
import "../css/Nav.css";
import * as IoIcons from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import { signOut } from '../api/FirebaseAPI';

function Nav(props) {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);

    const history = useHistory();

    const toggleSideBar = () => {
        setIsSideBarOpen(!isSideBarOpen);
    }

    const logout = () => {
        toggleSideBar();
        signOut(() => {
            history.push("/");
        })
    }

    if (props.disabled) {
        return (<div></div>);
    }

    return (
        <div>
            <div className="nav-container">
                <div className="nav-menuicon" onClick={toggleSideBar}>
                    <IoIcons.IoIosMenu />
                </div>
                <div className="nav-heading">
                    <h3>Auto Compliance</h3>
                </div>
            </div>

            <div className={isSideBarOpen ? "nav-sidebar active" : "nav-sidebar"} >
                <div className="nav-sidebar-header" onClick={toggleSideBar}>
                    <h4>Close</h4>
                    <IoIcons.IoMdClose className="icon" />
                </div>
                <ul>
                    <li onClick={toggleSideBar}>
                        <Link to="/dashboard">Home</Link>
                    </li>
                    <li onClick={toggleSideBar}>
                        <Link to="/Dockets">Job Cards</Link>
                    </li>
                    <li onClick={toggleSideBar}>
                        <Link to="/Reports">Reports</Link>
                    </li>
                    <li onClick={logout}>
                        <Link to="/">Log Out</Link>
                    </li>
                </ul>
            </div>

        </div >

    );
}

export default Nav;