import React, { useState, useEffect } from 'react';
import '../css/NewDocket.css';
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { getCurrentlySignedInUser, getDocket, getDB } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';

const EditDocket = ({ match }) => {
    const [name, setName] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [vin, setVin] = useState('');
    const [error, setError] = useState("");

    // const [profile, setProfile] = useState(false);
    const [user, setUser] = useState();
    // const [loading, setLoading] = useState(true);


    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());
    }, [user]);

    const setupUser = (user) => {
        setUser(user);

        getDocket(user.uid, match.params.id, (doc) => {
            setupValue(doc.data());
        }, (error) => {
            console.log('Error: ', error)
        })

    }

    const redirectToLogin = () => {
        history.push("/");
    }


    const updateName = (e) => {
        setName(e.target.value);
        setError("");
    }

    const updateMake = (e) => {
        setMake(e.target.value);
        setError("");
    }

    const updateModel = (e) => {
        setModel(e.target.value);
        setError("");
    }

    const updateVin = (e) => {
        setVin(e.target.value);
        setError("");
    }

    const setupValue = (data) => {
        setName(data.name);
        setMake(data.make);
        setModel(data.model);
        setVin(data.vin);
    }

    const submit = () => {
        getDB().collection('dockets: ' + user.uid).doc(match.params.id).update({
            name: name,
            make: make,
            model: model,
            vin: vin,
        }).then(() => {
            //console.log("document updated");
            history.push('/dockets');
        }).catch(error => {
            console.log("Error updating doc: ", error);
        })
    }

    const validate = () => {
        if (name.trim() === '') return false;
        if (make.trim() === '') return false;
        if (model.trim() === '') return false;
        if (vin.trim() === '') return false;

        return true;
    }

    return (
        <div className="newdocket-container" >
            <h1>Edit Docket</h1>
            <div className="newdocket-card">
                <CustomInput label="Name" placeholder="Name" type="text" onChange={updateName} value={name} />
                <CustomInput label="Make" placeholder="Make" type="text" onChange={updateMake} value={make} />
                <CustomInput label="Model" placeholder="Model" type="text" onChange={updateModel} value={model} />
                <CustomInput label="VIN" placeholder="VIN" type="text" onChange={updateVin} value={vin} />
                <CustomButton title="Update" type="btn-primary" onClick={submit} />
                <div className="newdocket-error">
                    {error}
                </div>
            </div>
        </div>
    );
}

export default EditDocket;