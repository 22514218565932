import React from 'react';
import '../css/DocketItem.css';
import { MdDelete, MdEdit } from "react-icons/md";

const DocketItem = (props) => {

    const handleClick = (e) => {
        console.log("evt:", e.target.id);
        if (e.target.id === 'icon') return;

        props.onClick(props);
    }

    const handleEdit = (e) => {
        e.stopPropagation();
        props.onEdit(props.id);
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        props.onDelete(props.id);
    }

    return (
        <div className="docket-item" onClick={handleClick} id='docket-item'>
            <div className="docket-item-icons" id="icon">
                <MdEdit className="docket-item-icon-style" onClick={handleEdit} />
                <MdDelete className="docket-item-icon-style" onClick={handleDelete} />
            </div>
            <div className="docket-item-row">
                <h4>Name:</h4>
                <p>{props.name}</p>
            </div>
            <div className="docket-item-row">
                <h4>Make:</h4>
                <p>{props.make}</p>
            </div>
            <div className="docket-item-row">
                <h4>Model:</h4>
                <p>{props.model}</p>
            </div>
            <div className="docket-item-row">
                <h4>VIN:</h4>
                <p>{props.vin}</p>
            </div>
            <div className="docket-item-row docket-date">
                {props.date}
            </div>
        </div>
    );
}

export default DocketItem;