import React, { useState } from 'react';
import { signIn } from '../api/FirebaseAPI';
import { useHistory, Link } from 'react-router-dom';
import '../css/Login.css';
import CustomButton from '../components/CustomButton.js';
import CustomInput from '../components/CustomInput.js';


const Login = () => {
    const [error, setError] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    let history = useHistory();

    const updateEmail = (e) => {
        setUsername(e.target.value);
        setError(false);
    }

    const updatePassword = (e) => {
        setPassword(e.target.value);
        setError(false);
    }

    const handleLogin = () => {
        signIn(username, password, successfulLogin, unsuccessfulLogin);
    }

    const successfulLogin = (user) => {
        history.push("/dashboard");
    }

    const unsuccessfulLogin = (error) => {
        setError(true);
        setErrorMsg(error);
    }

    return (
        <div className="login-container" >
            <div className="login-card">
                <div className="login-card-header">
                    <h2>Login</h2>
                </div>
                <div className="login-inputs">
                    <CustomInput label="Username" placeholder="Email" type="text" onChange={updateEmail} />
                    <CustomInput label="Password" placeholder="Password" type="password" onChange={updatePassword} />
                    <CustomButton title="Login" type="btn-primary" onClick={handleLogin} />
                </div>
                <div className="login-signup-link"> <Link to="/signup">Sign Up</Link> </div>
                <div className="login-error">
                    {error ? errMsg : ""}
                </div>
            </div>
        </div>
    );
}

export default Login;