import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import Nav from './components/Nav';
import { getCurrentlySignedInUser } from './api/FirebaseAPI';
import NewDocket from './screens/NewDocket';
import SignUp from './screens/SignUp';
import Dockets from './screens/Dockets';
import Details from './screens/Details';
import EditDocket from './screens/EditDocket';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    getCurrentlySignedInUser(redirect, hideNav);
  }, []);

  const redirect = () => {
    setLoggedIn(true);
  }

  const hideNav = () => {
    setLoggedIn(false);
  }

  return (
    <Router >
      <div className="app" >
        <Nav disabled={!loggedIn} />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/newdocket" component={NewDocket} />
          <Route path="/dockets" exact component={Dockets} />
          <Route path="/dockets/:id" component={Details} />
          <Route path="/editdocket/:id" component={EditDocket} />
          <Route path="/signup" component={SignUp} />
        </Switch>
      </div>
    </Router>

  );
}

export default App;
