import React from 'react';
import '../css/CustomInput.css'


const CustomInput = (props) => {
    return (
        <div className="custominput-inputs">
            <div className="custominput-label">{props.label}</div>
            <input placeholder={props.placeholder} type={props.type} className="custominput-input-item " onChange={props.onChange} value={props.value} />
        </div>
    );
}

export default CustomInput;