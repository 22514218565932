import * as firebase from "firebase";
import "firebase/firestore";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyClchRSLPm4FxsDTfOOj7SM0mKwKbParBI",
    authDomain: "autocompliance-bfe6d.firebaseapp.com",
    databaseURL: "https://autocompliance-bfe6d.firebaseio.com",
    projectId: "autocompliance-bfe6d",
    storageBucket: "autocompliance-bfe6d.appspot.com",
    messagingSenderId: "461041260550",
    appId: "1:461041260550:web:cef136278e6a707fa18b87",
    measurementId: "G-PW8KXMKC6N"
};

export default !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();



export function ifLoggedIn(callback) {
    firebase.auth().onAuthStateChanged(user => {
        if (!user) {
            callback(false);
        } else {
            callback(true);
        }
    });
}


export function signIn(username, password, passCallback, errorCallback) {
    try {
        firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then(user => {
                passCallback(user);
            })
            .catch(error => {
                console.log(error.toString());
                errorCallback(error.toString());
            });
    } catch (error) {
        console.log(error.toString());
        return false;
    }
}

export function signOut(passCallback) {
    try {
        firebase
            .auth()
            .signOut()
            .then(() => {
                passCallback();
            });
    } catch (error) {
        console.log(error.toString());
    }
}

export function getCurrentlySignedInUser(setUser, noUser) {
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            setUser(user);
            //console.log("user signed in");
        } else {
            noUser();
            //console.log("no user logged in");
        }
    })
}

export function getUserProfile() {
    return firebase.firestore().collection("users");
}

export function createUser(username, password, passCallback, errorCallback) {
    try {

        firebase
            .auth()
            .createUserWithEmailAndPassword(username, password)
            .then(cred => {
                passCallback(cred);
            })
            .catch(error => {
                console.log(error.toString());
                errorCallback(error.toString());
            });
        return true;
    } catch (error) {
        console.log(error.toString());
        return false;
    }
}

export function createUserProfile(data, userID) {
    firebase
        .firestore()
        .collection("users")
        .doc(userID)
        .set({
            name: data.name,
            email: data.email,
            drafts: 0,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            submitted: 0
        });
}

export function addDocket(userID, data, passCallback, errorCallback) {
    const db = firebase.firestore();

    db.collection('dockets: ' + userID).add({
        name: data.name,
        make: data.make,
        model: data.model,
        vin: data.vin,
        createdAt: data.createdAt,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),

    }).then(docRef => {
        passCallback();
    }).catch(error => {
        errorCallback(error.toString());
    });
}

export function addJob(userID, jobid, data, passCallback, errorCallback) {
    const db = firebase.firestore();

    db.collection('dockets: ' + userID).doc(jobid).collection('jobs: ' + jobid).add({
        quantity: data.quantity,
        desc: data.desc,
        rate: data.rate,
        amount: data.amount,
        createdAt: data.createdAt,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),

    }).then(docRef => {
        passCallback();
    }).catch(error => {
        errorCallback(error.toString());
    });
}

export function incrementDocket(userID) {
    const increment = firebase.firestore.FieldValue.increment(1);

    const ref = firebase.firestore().collection('users').doc(userID);

    ref.update({ drafts: increment });
}

export function decrementDocket(userID) {
    const increment = firebase.firestore.FieldValue.increment(-1);

    const ref = firebase.firestore().collection('users').doc(userID);

    ref.update({ drafts: increment });
}

export function getDockets(userID, passCallback, errorCallback) {
    const db = firebase.firestore();

    db.collection('dockets: ' + userID).orderBy('timeStamp').get().then(snapshot => {
        passCallback(snapshot);
    }).catch(error => {
        errorCallback(error.toString());
    })

}

export function getDocket(userID, docketID, passCallback, errorCallback) {
    const db = firebase.firestore();

    db.collection('dockets: ' + userID).doc(docketID).get().then(doc => {
        passCallback(doc);
    }).catch(error => {
        errorCallback(error.toString());
    })
}

export function stampToDate(timestamp) {
    return timestamp.toDate();
}

export function getDB() {
    return firebase.firestore();
}