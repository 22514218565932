import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentlySignedInUser, getDB } from '../api/FirebaseAPI';
import "../css/Dockets.css";
import DocketItem from '../components/DocketItem.js';

const Dockets = () => {
    // const [profile, setProfile] = useState(false);
    const [user, setUser] = useState();
    // const [loading, setLoading] = useState(true);

    const [dockets, setDockets] = useState([]);


    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());


    }, []);

    const setupUser = (user) => {
        setUser(user);
        const ref = getDB().collection('dockets: ' + user.uid).orderBy('timeStamp');

        return ref.onSnapshot(querySnapshot => {
            const list = [];

            querySnapshot.forEach(doc => {
                let data = doc.data();

                list.push({
                    ...data, id: doc.id
                });
            });

            setDockets(list);


        });
    }

    const redirectToLogin = () => {
        history.push("/");
    }

    const handleItemClick = (data) => {
        history.push("/dockets/" + data.id);
    }

    const handleEdit = (id) => {
        history.push("/editdocket/" + id);
    }

    const handleDelete = (id) => {
        var a = window.confirm('Are you sure you wish to delete this?');

        if (a) {
            getDB().collection('dockets: ' + user.uid).doc(id).delete().then(() => {
                //document is deleted
            }).catch(error => {
                console.log("Error deleting Job: ", error);
            });
        }


    }



    return (
        <div className="docket-container">
            <h1>Job Cards</h1>
            <div className="docket-item-container">
                {dockets.map(item => {
                    return (
                        <DocketItem
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            make={item.make}
                            model={item.model}
                            vin={item.vin}
                            date={item.createdAt}
                            onClick={handleItemClick}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Dockets;