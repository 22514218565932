import React, { useState } from 'react';
import '../css/Overlay.css';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';

const Overlay = (props) => {
    const [quantity, setQuantity] = useState('');
    const [desc, setDesc] = useState('');
    const [rate, setRate] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const updateQuantity = (e) => {
        setQuantity(e.target.value);
        setError("");
    }
    const updateDesc = (e) => {
        setDesc(e.target.value);
        setError("");
    }
    const updateRate = (e) => {
        setRate(e.target.value);
        setError("");
    }
    const updateAmount = (e) => {
        setAmount(e.target.value);
        setError("");
    }

    const closeOverlay = (e) => {
        if (e.target.id !== 'overlay-background') return;
        props.closeOverlay();
    }

    const submit = () => {
        let data = {
            quantity: quantity,
            desc: desc,
            rate: rate,
            amount: amount,
            createdAt: new Date().toDateString(),
        };

        props.onSubmit(data);
        props.closeOverlay();
    }

    return (
        <div className={props.isVisible ? "overlay-container" : "overlay-container hidden"} onClick={closeOverlay} id="overlay-background">
            <div className="overlay-card" >
                <CustomInput label="QTY" placeholder="Quantity" type="text" onChange={updateQuantity} />
                <CustomInput label="Description" placeholder="Description" type="text" onChange={updateDesc} />
                <CustomInput label="Rate" placeholder="Rate" type="text" onChange={updateRate} />
                <CustomInput label="Amount" placeholder="Amount" type="text" onChange={updateAmount} />
                <CustomButton title="Create" type="btn-primary" onClick={submit} />
                <div className="overlay-error">
                    {error}
                </div>
            </div>
        </div>
    );
}

export default Overlay;