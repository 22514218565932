import React from 'react';
import '../css/Button.css';

const CustomButton = (props) => {
    let myClass = "btn-primary";

    if (props.type) {
        myClass = props.type;
    }

    return (
        <div className="btn-custom-button" onClick={props.onClick} style={props.style && props.style}>
            <div className={myClass}>{props.title}</div>
        </div>
    );
}

export default CustomButton;