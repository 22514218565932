import React, { useState, useEffect } from 'react';
import { getUserProfile, getCurrentlySignedInUser } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';
import "../css/Dashboard.css";
import CustomButton from '../components/CustomButton';


const Dashboard = () => {
    const [profile, setProfile] = useState(false);
    // const [user, setUser] = useState();
    // const [loading, setLoading] = useState(true);

    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());

    }, []);

    const setupUser = (user) => {
        // setUser(user);
        getUserProfile().doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                setProfile(doc.data());
                // setLoading(false);
            }
        });
    }

    const redirectToLogin = () => {
        history.push("/");
    }

    return (
        <div className="dashboard-container">
            <div>
                <h1>Dashboard</h1>
            </div>
            <div className="dashboard-card">
                <h4>User Details</h4>

                <span>Name</span>
                <p>{profile.name}</p>

                <span>Email</span>
                <p>{profile.email}</p>

                <span>Number of submited dockets</span>
                <p>{profile.submitted}</p>

                <span>Number of Drafts</span>
                <p>{profile.drafts}</p>

                <CustomButton title="New Job Card" type="btn-primary" onClick={() => { history.push("/newdocket") }} />

                <CustomButton title="Review Job Cards" type="btn-primary" onClick={() => { history.push("/dockets") }} />

            </div>
        </div>
    );
}

export default Dashboard;
